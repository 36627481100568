<template>
  <div class="" style="display: inline-block;">

    <div v-if="show_tag === 'big'" class="person_view_big">
      <div>
        <OnePersonLittle v-if="(!current_year && current_year !== 0) || current_year === 9999" style="vertical-align: middle;" :person_info="info"  @click="pull_up()"></OnePersonLittle>
        <OnePersonTime v-else style="vertical-align: middle;" :person_info="info" :current_year="current_year"  @click="pull_up()"></OnePersonTime>
        <div class="name_tags" style="vertical-align: top;">
          <div :class="'name_tag color_' + tag.color" v-for="(tag, index) in info.tag_list" :key="index" >{{tag.word}}</div>
        </div>
      </div>
      <div>
        <span style="color:#67C23A; vertical-align: middle;" >{{info.dynasty}} </span>
      </div>
      <div>
        <text style="color:#67C23A;"  @click="go_to(info.id)">{{info.num_all}}</text>
        <span class="gray" style="vertical-align: middle;"> {{info.index}}</span>
      </div>
      <div>
        <text style="color:#555;">{{info.desc}}</text>
      </div>
      <div>
        <el-button  type="success" class="mx-1"  plain  @click="baike_edit_percent('num_all')" size="small" >1/10</el-button>
        <el-button type="primary" plain size='small' style="width:42px" @click="search_word('baidu', info.word_easy)">百度</el-button>
        <el-button type="primary" plain size='small' style="width:42px" @click="search_word('baike', info.word_easy)">百科</el-button>
      </div>
      <div>
        <text style="color:#67C23A;">{{info.year_birth}}</text>
        <text style="color:#555;">-</text>
        <text style="color:#67C23A;">{{info.year_death}}</text>
      </div>

      <div>
        <el-button :type="info.state_info.in_my === 1 ? 'success':'info'" plain size='small' style="width:42px" @click="change_one_zici('in_my')">in_my</el-button>
        <el-button :type="info.state_info.fix === 1 ? 'primary':'info'" plain size='small' style="width:42px" @click="change_one_zici('fix')">fix</el-button>
        <el-button :type="info.state_info.state<1? 'danger':'info'" plain size='small' style="width:42px" @click="change_one_zici('state')">{{info.state_info.state >0?'yes':'not'}}</el-button>
      </div>
    </div>
    <div v-else-if="show_tag === 'middle'" class="person_view_middle">
      <div>
        <OnePersonLittle  v-if="(!current_year && current_year !== 0) || current_year === 9999" style="vertical-align: top;" :person_info="info" @click="pull_up()" />
        <OnePersonTime v-else style="vertical-align: top;" :person_info="info" :current_year="current_year"  @click="pull_up()"></OnePersonTime>
        <div class="name_tags" style="vertical-align: top;">
          <div :class="'name_tag color_' + tag.color" v-for="(tag, index) in info.tag_list" :key="index" >{{tag.word}}</div>
        </div>
      </div>
      <div style="white-space: nowrap; overflow: hidden;">
        <span style="color:#aaa; font-size: 14px;">{{info.desc}}</span>
        <span class="go_tag"  @click="go_to(info.id)">go</span>
        <!-- <span class="go_tag"  @click="go_to(info.id)">go</span> -->
          <!-- <span style="color:#ddd;" @click="go_to(info.id)">more</span> -->
      </div>
    </div>
    <div v-else-if="show_tag === 'little'" class="person_view_little" >
      <div>
        <OnePersonLittle  v-if="(!current_year && current_year !== 0) || current_year === 9999" style="vertical-align: top;" :person_info="info" @click="pull_up()" />
        <OnePersonTime v-else style="vertical-align: top;" :person_info="info" :current_year="current_year"  @click="pull_up()"></OnePersonTime>

        <div class="name_tags" style="vertical-align: top;">
          <div :class="'name_tag color_' + tag.color" v-for="(tag, index) in info.tag_list" :key="index" >{{tag.word}}</div>
        </div>
      </div>
    </div>
    <OnePersonWord v-else-if="show_tag === 'word'"  :person_info="info" @click="pull_up()" />
    <OnePersonLittle v-else  :person_info="info" @click="pull_up()" />

    <el-drawer v-model="show.drawer" :show-close="false" :direction="direction" :size=" !another_id ? 50 + '%' : 90 + '%'">
      <el-skeleton v-if="loading" :rows="5" animated  style="width: 90%;margin: 0px 100px 30px 30px; text-align: left; display: inline-block; vertical-align: top;" />
      <div v-else   :style="another_id ? 'width: 44%; float:right; margin: 0px 50px 30px 30px; text-align: left;' : 'width: 90%; float:right; margin: 0px 50px 30px 30px;text-align: left;' ">
        <PersonInfoEditView v-if="is_editing" :person_info="detail_info" />
        <PersonInfoView  v-else :person_info="detail_info"/>

        <div class="persons">
          <div class="checkbox"  style="text-align:left; margin: 0px 0px 20px 0px;" v-if=" detail_info.year_info.end > detail_info.year_info.start">
              <span style="font-weight: bold;">{{ detail_info.year_info.current }}年 </span>
              <el-slider :format-tooltip="(value) => `${detail_info.year_info.certitude === 'guess' ? '约' :  detail_info.year_info.certitude === 'less_start' ? '猜' :  detail_info.year_info.certitude === 'less_end' &&  value - detail_info.year_info.start >= 50 ? '或' : ''}${value-detail_info.year_info.start}岁`"  v-model="detail_info.year_info.current" :min="detail_info.year_info.start" :max="detail_info.year_info.end" />
          </div>
          <PersonListsView :person_info="detail_info" :callback="personLists_Callback"/>
        </div>

        <PersonCheckView v-if="is_editing" :person_info="detail_info" />
        <FooterView show_what="line1" :padding_left="1"/>

      </div>
      <AnotherPersonView v-if="another_id" :another_id="another_id" :main_person_id="detail_info.id"></AnotherPersonView>

    </el-drawer>
    <el-dialog v-model="show.login" title=""  class="login_view" width="30%"  center :show-close="false" @closed="close_view"><LoginDialog /></el-dialog>
    <el-drawer v-model="show.little" :show-close="false" direction="btt" :with-header="false">
      <div style="margin-left: 48%;" v-if="!loading3" class="left_div"><PersonInfoView :person_info="info" tag="little"/></div>
    </el-drawer>
  </div>
</template>
<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import PersonInfoView from '@/components/divs/PersonInfoView'
import PersonListsView from '@/components/divs/PersonListsView'
import OnePersonLittle from '@/components/items/OnePersonLittle'
import OnePersonTime from '@/components/items/OnePersonTime'
import OnePersonWord from '@/components/items/OnePersonWord'
import PersonInfoEditView from '@/components/divs/PersonInfoEditView'
import PersonCheckView from '@/components/divs/PersonCheckView'
import AnotherPersonView from '@/components/divs/AnotherPersonView'
import FooterView from '@/components/other/FooterView'

import LoginDialog from '@/components/other/LoginDialog'
import { isEditing } from '@/components/config.js'

export default {
  name: 'OnePersonCanPull',
  props: {
    person_info: Map,
    tag: String,
    action: String,
    current_year: Number
  },
  components: {
    AnotherPersonView, PersonInfoView, PersonCheckView, PersonInfoEditView, OnePersonLittle, OnePersonTime, OnePersonWord, LoginDialog, FooterView, PersonListsView
  },
  data () {
    return {
      loading: true,
      info: this.person_info,
      base_img: store.state.base_img,
      nextAction: this.action,
      base_url: store.state.base_url,
      detail_info: { year_info: {} },
      another_id: false,
      show_tag: this.tag,
      show_add_rword_view: false,
      is_editing: isEditing,
      show: {},
      little_info: {}
    }
  },
  methods: {
    pull_up () {
      console.log(this.nextAction)
      if (this.nextAction === 'goTo') {
        this.go_to(this.info.id)
      } else {
        this.show.little = true
      }
      //
      // this.show.drawer = true
      // axios.post(Utils.check_is_niming() ? '/person_detail_unlogin/' : '/person_detail/', { id: this.info.id })
      //   .then(res => {
      //     if (res.data.code !== 200) {
      //       Utils.alert(res.data.msg)
      //       return
      //     }
      //     this.detail_info = res.data.data.info
      //     this.loading = false
      //   })
    },
    pull_up_another_person_detail (e) {
      this.another_id = e
    },
    personLists_Callback (e) {
      // window.open(routeData.href, '_blank')
      // this.another_id = e
      // this.show.little = true
      // this.little_info = e
      // this.loading3 = false
      this.another_id = e.id
    },
    baike_edit_percent (tag) {
      axios.post('/baike_edit_percent/', { id: this.info.id, tag: tag })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.info = res.data.data.info
        })
    },
    go_to (e) {
      const routeData = this.$router.resolve({ name: 'Person_detail', params: { id: e } })
      window.open(routeData.href, '_blank')
    },
    search_word (a, word) {
      if (a === 'baidu') {
        window.open('https://www.baidu.com/s?wd=' + word, '_blank')
      } else if (a === 'baike') {
        window.open('https://baike.baidu.com/item/' + word + '/' + this.info.baike_id, '_blank')
      }
    },
    change_one_zici (e) {
      console.log(e)
      axios.post('/change_one_zici/', { id: this.info.id, action: e })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.info.state_info = res.data.data.state_info
        })
    }
  }
}
</script>

<style>
.person_view_big{
  display: inline-block;
  width:240px;
  vertical-align: top;
  margin: 0px 20px 30px 0px;
}
.person_view_middle{
  display: inline-block;
  width: 200px;
  margin: 0px 20px 20px 0px;
}
.person_view_little{
  margin: 0px 20px 20px 0px;
}
.name_tags{
  vertical-align: top;
  display: inline-block;
  width: 16px;
  /* display: grid;
  grid-auto-flow: column; */
}
.name_tag{
  margin: 0px 0px 0px 0px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 10px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 10px;
  vertical-align: top;
  transform: scale(0.9);
}
.color_pink{
  color: pink;
  border: 1px solid pink;

}
.color_blue{
  color: rgb(21, 101, 158);
  border: 1px solid rgb(21, 101, 158);
}
.color_lightblue{
  color: lightblue;
  border: 1px solid lightblue;

}
.color_green{
  color: rgb(203, 167, 37);
  border: 1px solid rgb(203, 167, 37);

}
.color_yellow{
  color: rgb(190, 206, 23);
  border: 1px solid rgb(190, 206, 23);

}
.color_orange{
  color: orange;
  border: 1px solid orange;
}
.go_tag{
  cursor:pointer;
  color:#999;
  border: 1px solid #bbb;
  border-radius: 20px;
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  margin-right: 2px;
  text-align: center;
  vertical-align: middle;
  transform: scale(0.6);
  transform-origin: center;
}

.group_view .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color:#50b7c1;border-color:#50b7c1;}
.group_view .el-checkbox__input.is-checked + .el-checkbox__label{color:#50b7c1;}
.group_view .el-checkbox.is-bordered.is-checked{border-color:#50b7c1;}
.group_view .el-checkbox__input.is-focus .el-checkbox__inner{border-color: #50b7c1;}
.net_view .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color:#de8af8;border-color:#de8af8;}
.net_view .el-checkbox__input.is-checked + .el-checkbox__label{color:#de8af8;}
.net_view .el-checkbox.is-bordered.is-checked{border-color:#de8af8;}
.net_view .el-checkbox__input.is-focus .el-checkbox__inner{border-color: #de8af8;}
.img_window_big .bytes{display: inline-block; margin: 3px 8px;}
.el-drawer{
  background: rgb(243, 247, 251);
}
</style>
