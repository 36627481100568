<template>
  <div class="name_word">
    <span :class="['name_word', 'gender_' + info.gender, info.gender == 0 ? '' : 'minzu_' + info.tag_minzu, info.gender == 0 &&  info.tag_minzu ==2 ? 'gender_0_minzu_2' : '']" >{{ info.word }}</span>
    <router-link  target="_blank" :to="{ name: 'Person_detail', params: { id: info.id }}"><span  style="display: inline-block ;width:1px;height:1px; overflow: hidden;" >{{ info.word }} - 历史年轮 同时代人物，年龄动态变化</span></router-link>
  </div>
</template>
<script>

export default {
  name: 'OnePersonWord',
  props: {
    person_info: Map
  },
  data () {
    return {
      info: this.person_info
    }
  },

  methods: {

  }
}
</script>

<style scoped>
.name_word{
  cursor:pointer;
  display: inline-block;
  color: #50b7c1;
  margin:0px 2px 0px 2px;
}

.gender_0{
  color: pink;
}

.minzu_2{
  color: #de8af8;
}
.name_word .gender_0_minzu_2{
  background-image: linear-gradient(to right, pink, #de8af8);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
}
</style>
